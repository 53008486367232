const { useState } = require("react");

const useGetSessions = () => {
  const [sessions, setSessions] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const updateSessions = async (sessions) => {
    //TODO
  };

  const getSessions = async () => {
    setIsLoading(true);
    setSessions([
      {
        title: "Monday",
        activities: [
          {
            id: "a240008b-beb8-44e2-a33c-b83338e6f9a1",
            title: "Pool Swim",
            location: "University Pool",
            description:
              "1 hour coached swimming session in summer months. Open to B/C/D squad. Book through Spond app.",
            price: "£5",
            time: "19:00 (Summer Only)",
            headline: "B/C/D Squad",
          },
        ],
      },
      {
        title: "Tuesday",
        activities: [
          {
            id: "669e23ad-693f-4151-b09d-49fc8d5854ce",
            title: "Group Run",
            location: "Up & Running, BS6 7QA",
            description:
              "This is an uncoached run session organised and led by the lovely guys from Up & Running, which is open to BADTri members. Typically, four groups, each catering for a different ability level, will set off at 6.30pm prompt, from either outside the shop or opposite the shop. It is best to arrive about 10 minutes early. Each group runs at a steady pace through local parks and nature reserves (in summer) or pounds the pavements (in winter). Book through Spond app.",
            price: "FREE",
            time: "18:30",
          },
        ],
      },
      {
        title: "Wednesday",
        activities: [
          {
            id: "70836005-e9b6-4015-81bf-e9e0a9fd7537",
            title: "Pool Swim",
            location: "Horfield Leisure Centre",
            description:
              "1 hour coached swimming session. Open to all squads. Book through Spond app.",
            price: "£5",
            time: "06:30",
            headline: "All squads",
          },
          {
            id: "da150f31-bf74-47b9-b2e5-5bbc1b541e79",
            title: "Track",
            location: "SGS College, BS34 8LP",
            description:
              "An action-packed 75-minute track session for all abilities (seniors only) at the well-appointed athletics facilities of SGS College. The venue is between the MOD in Filton and Bristol Park Way station. The session is jointly coached by Bristol & West Athletics Club and BADTri coaches. Book through Spond app.",
            price: "£5",
            time: "18:15",
            headline: "Seniors only",
          },
          {
            id: "d3ca5b3d-8a80-4c77-bb5b-d93c76ceff32",
            title: "Pool Swim",
            location: "University Pool",
            description:
              "1 hour coached swimming session. Open to all squads. Book through Spond app.",
            price: "£5",
            time: "19:00",
            headline: "All squads",
          },
        ],
      },
      {
        title: "Thursday",
        activities: [
          {
            id: "b725e486-8013-498d-9db6-26b36afa84d3",
            title: "Pool Swim",
            location: "University Pool",
            description:
              "1 hour coached swimming session. Open A/B squad. Book through Spond app.",
            price: "£5",
            time: "06:30",
            headline: "A/B Squad",
          },
          {
            id: "c9354b00-f657-46f9-b11e-d49748531670",
            title: "Fast Ride",
            location: "Pump House, BS8 4PZ",
            description:
              "A quick ride, finishing with a chance for a drink and a chat at a local watering hole. This is an unled ride that follows a tw-fixed route, during British Summer Time only. Average speed 19-20mph. Book through Spond app.",
            price: "FREE",
            time: "18:00",
          },
          {
            id: "67f94c56-4cf4-4b0d-86eb-9327643a21c1",
            title: "Track",
            location: "Whitehall Athletics Track",
            description:
              "A 75-minute running track session hosted by Bristol & West Athletics Club at Whitehall Athletic Track. This session focuses on speed and endurance. Book a place through the Spond app for free - but pay at the track.",
            price: "£5",
            time: "19:10",
            headline: "Seniors only",
          },
        ],
      },
      {
        title: "Friday",
        activities: [],
      },
      {
        title: "Saturday",
        activities: [
          {
            id: "24bb5647-f7b7-487f-98fd-4820f47876d6",
            title: "Intro Ride",
            location: "Ashton Court",
            description:
              "This ride is intended to introduce senior members to group riding. Each introductory ride will be led by an experienced club appointed leader, will proceed at the pace of the slowest rider and is intended to be manageable in terms of distance and difficulty. Distance can vary between 25km and 50km. Book through Spond app.",
            price: "FREE",
            time: "09:00",
          },
          {
            id: "46886a3e-a622-492f-9c27-ecb95469a331",
            title: "Bike & Swim",
            location: "Henbury Leisure Centre",
            description:
              "This session is a one hour bike session followed by a one hour swim session. It is intended for our junior members. Book through Spond app.",
            price: "£4",
            time: "14:30",
            headline: "Juniors only",
          },
        ],
      },
      {
        title: "Sunday",
        activities: [
          {
            id: "8681f794-9444-4448-998a-dd47c74d2ec6",
            title: "Pool Swim",
            location: "University Pool",
            description:
              "1 hour coached swimming session. Open to all squads. Book through Spond app.",
            price: "£5",
            time: "07:50",
            headline: "all squads",
          },
          {
            id: "476f9bc9-104e-4173-9552-406eac3b19f4",
            title: "Open Water Swim",
            location: "Henleaze Lake",
            description:
              "1 hour open water swimming session. Open to all squads. Book through Spond app.",
            price: "£5",
            time: "09:30 (May - September)",
            headline: "Seniors Only",
          },
        ],
      },
    ]);
    setIsLoading(false);
    console.log("hello");
  };

  return { getSessions, sessions, isLoading, error, updateSessions };
};

export default useGetSessions;
