import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as DollarIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as ClockIcon } from "feather-icons/dist/icons/clock.svg";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";
import { ReactComponent as AddIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as DeleteIcon } from "feather-icons/dist/icons/trash-2.svg";
import { ReactComponent as EditIcon } from "feather-icons/dist/icons/edit.svg";
import useGetSessions from "./hooks/useGetSessions";
import { getSessionSliderSettings } from "./SliderSettings";

const SliderContainer = tw.div`tw-relative tw-px-4 sm:tw-px-0 -tw-mt-12 tw-mb-10`;
const Content = tw.div`tw-max-w-screen-xl tw-mx-auto tw-pt-8 lg:tw-pt-12 tw-pb-16 lg:tw-pb-20 lg:tw-px-12`;

const CardSlider = styled(Slider)`
  ${tw`tw-mt-16 tw-overflow-hidden`}
  .slick-track {
    ${tw`tw-flex`}
  }
  .slick-slide {
    ${tw`tw-h-auto tw-flex tw-justify-center tw-mb-1`}
  }
  .slick-list {
    overflow: tw-hidden;
  }
`;

const CardType2 = tw.div`tw-bg-gray-200 tw-w-full tw-h-auto tw-flex! tw-flex-col tw-border tw-max-w-sm tw-rounded-2xl tw-relative focus:tw-outline-none tw-px-4 sm:tw-px-0`;
const HorizontalLine = tw.hr`-tw-mr-4 -tw-ml-4 sm:tw-mr-0 sm:tw-ml-0 tw-border-gray-400`;
const TextInfo = tw.div`tw-py-6 sm:tw-px-10 sm:tw-py-6`;
const TextInfoTop = tw.div`tw-py-6 sm:tw-px-10 sm:tw-py-6 tw-bg-primary-900 tw-text-gray-100 tw-rounded-t-2xl -tw-ml-4 -tw-mr-4 tw-pr-4 tw-pl-4 sm:tw-ml-0 sm:tw-mr-0`;
const TitleReviewContainer = tw.div`tw-flex tw-flex-row sm:tw-justify-between tw-items-center tw-mb-6`;
const TitleReviewContainerTop = tw.div`tw-flex tw-flex-row sm:tw-justify-between tw-items-center`;
const Title = tw.h5`tw-text-2xl tw-font-bold`;
const SubTitle = tw.h6`tw-text-lg tw-font-bold`;
const Description = tw.p`tw-text-sm tw-leading-loose tw-mt-2 sm:tw-mt-4`;
const SecondaryInfoContainer = tw.div`tw-mt-2 sm:tw-mt-4`;
const IconWithText = tw.div`tw-flex tw-items-end tw-mr-6 tw-my-2`;
const Pill = tw.div`tw-text-xs tw-font-bold tw-px-3 tw-rounded tw-py-2 tw-uppercase tw-bg-primary-400 tw-text-gray-100 tw-leading-none sm:tw-mt-0 sm:tw-w-auto tw-text-center tw-ml-auto`;
const IconContainer = styled.div`
  ${tw`tw-inline-block tw-rounded-full tw-p-2 tw-bg-gray-700 tw-text-gray-100`}
  svg {
    ${tw`tw-w-3 tw-h-3`}
  }
`;
const Text = tw.div`tw-ml-2 tw-text-sm tw-font-semibold tw-text-gray-800 tw-mb-auto tw-mt-auto`;

export default ({ isEditable }) => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [setSliderRef] = useState(null);
  const { getSessions, sessions, isLoading } = useGetSessions();

  console.log("HELLOOOO A");

  useEffect(() => {
    console.log("CALLING GET SESSIONS");
    getSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveActivityChange = (id) => {};

  const EditActivityButton = ({ sessionDetails }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
      <>
        <Button
          tw="tw-mr-2 tw-mt-4"
          isLoading={false}
          spinner={<BeatLoader size={8} color="white" />}
          colorScheme="yellow"
          rightIcon={<EditIcon />}
          onClick={onOpen}
          size="md"
        >
          Edit
        </Button>

        <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Activity</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl tw="tw-mb-4">
                <FormLabel>Name</FormLabel>
                <Input
                  id={"activity-name"}
                  defaultValue={sessionDetails.title}
                />
                <FormHelperText>
                  3 words max to accommodate for mobile screens
                </FormHelperText>
              </FormControl>

              <FormControl tw="tw-mb-4">
                <FormLabel>Tag</FormLabel>
                <Input
                  id={"activity-tag"}
                  defaultValue={sessionDetails.headline}
                />
                <FormHelperText>optional - keep very short</FormHelperText>
              </FormControl>

              <FormControl tw="tw-mb-4">
                <FormLabel>Location</FormLabel>
                <Input
                  id={"activity-location"}
                  defaultValue={sessionDetails.location}
                />
                <FormHelperText>
                  keep as short as possible to prevent line wrap on smaller
                  devices
                </FormHelperText>
              </FormControl>

              <FormControl tw="tw-mb-4">
                <FormLabel>Time</FormLabel>
                <Input
                  id={"activity-time"}
                  defaultValue={sessionDetails.time}
                />
                <FormHelperText>24 hour clock</FormHelperText>
              </FormControl>

              <FormControl tw="tw-mb-4">
                <FormLabel>Price</FormLabel>
                <Input
                  id={"activity-price"}
                  defaultValue={sessionDetails.price}
                />
                <FormHelperText>£X or FREE</FormHelperText>
              </FormControl>

              <FormControl tw="tw-mb-4">
                <FormLabel>Description</FormLabel>
                <Textarea
                  id={"activity-description"}
                  defaultValue={sessionDetails.description}
                />
                <FormHelperText>
                  3 or 4 lines of information is ideal so users can see all
                  activities on a specific day without having to scroll down
                </FormHelperText>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button tw="tw-mr-2" variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  saveActivityChange(sessionDetails.ID);
                }}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  return (
    <Content>
      <SliderContainer tw="tw-mt-10">
        <CardSlider
          ref={setSliderRef}
          {...getSessionSliderSettings(isEditable)}
        >
          {!isLoading &&
            sessions &&
            sessions.length > 0 &&
            sessions.map((card, index) => (
              <CardType2 key={index}>
                <TextInfoTop>
                  <TitleReviewContainerTop>
                    <Title>{card.title}</Title>
                    {isEditable && (
                      <Button
                        isLoading={false}
                        spinner={<BeatLoader size={8} color="white" />}
                        colorScheme="yellow"
                        rightIcon={<AddIcon />}
                        onClick={() => {}}
                        size="sm"
                      >
                        Add
                      </Button>
                    )}
                  </TitleReviewContainerTop>
                </TextInfoTop>
                <>
                  {card.activities.map((activity) => (
                    <>
                      <HorizontalLine />
                      <TextInfo>
                        <SecondaryInfoContainer>
                          <TitleReviewContainer>
                            <SubTitle>{activity.title}</SubTitle>
                            {activity.headline && (
                              <Pill>{activity.headline}</Pill>
                            )}
                          </TitleReviewContainer>
                        </SecondaryInfoContainer>
                        <SecondaryInfoContainer>
                          {activity.location && (
                            <IconWithText>
                              <IconContainer>
                                <LocationIcon />
                              </IconContainer>
                              <Text>{activity.location}</Text>
                            </IconWithText>
                          )}
                          {activity.time && (
                            <IconWithText>
                              <IconContainer>
                                <ClockIcon />
                              </IconContainer>
                              <Text>{activity.time}</Text>
                            </IconWithText>
                          )}
                          {activity.price && (
                            <IconWithText>
                              <IconContainer>
                                <DollarIcon />
                              </IconContainer>
                              <Text>{activity.price}</Text>
                            </IconWithText>
                          )}
                        </SecondaryInfoContainer>
                        <Description>{activity.description}</Description>
                        {isEditable && (
                          <>
                            <EditActivityButton sessionDetails={activity} />
                            <Button
                              tw="tw-mt-4"
                              spinner={<BeatLoader size={8} color="white" />}
                              colorScheme="yellow"
                              rightIcon={<DeleteIcon />}
                              onClick={() => {}}
                              size="md"
                            >
                              Delete
                            </Button>
                          </>
                        )}
                      </TextInfo>
                    </>
                  ))}
                  {card.activities.length === 0 && (
                    <>
                      <HorizontalLine />
                      <TextInfo>
                        <SecondaryInfoContainer>
                          <TitleReviewContainer>
                            <SubTitle>No Sessions</SubTitle>
                          </TitleReviewContainer>
                        </SecondaryInfoContainer>
                        <Description>
                          There are currently no club sessions on a Friday - but
                          our members are always heading out, so there's bound
                          to be something going on!
                        </Description>
                      </TextInfo>
                    </>
                  )}
                </>
              </CardType2>
            ))}
        </CardSlider>
      </SliderContainer>
    </Content>
  );
};
