import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/header/Header.js";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings";
import HorizontalPersonCardSlider from "components/horizontalPersonCardSlider/HorizontalPersonCardSlider";
import SessionsSlider from "components/sessionsSlider/SessionsSlider";

export default () => {
  const HeadingWithControl = tw.div`tw-pt-16 tw-px-8 sm:tw-px-0 tw-text-center`;
  const Heading = tw(SectionHeading)`tw-text-center`;
  const Paragraph = tw.p`tw-text-center lg:tw-my-8 tw-text-sm lg:tw-text-base tw-font-medium tw-text-gray-600 tw-max-w-2xl tw-mx-auto`;

  return (
    <AnimationRevealPage>
      <Header />
      <HeadingWithControl>
        <Heading>Our Sessions</Heading>
        <Paragraph>
          Find below a selection of our regular weekly sessions and the coaches
          who'll be running them. We use Spond to manage session bookings - To
          see all of our socials, sessions and races join us and get access to a
          whole load of other events.
        </Paragraph>
      </HeadingWithControl>
      <HorizontalPersonCardSlider />
      <SessionsSlider />
      <Footer />
    </AnimationRevealPage>
  );
};
